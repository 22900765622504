:root {
  /*Colors*/
  --color-one: #212226; /*Black*/
  --color-two: #B3B3B3; /*Grey*/
  --color-three: #F2F2F0; /*Grey background*/
  --color-four: #FFDA54; /*Yellow*/
  --color-five: #FFE27A; /*Yellow Hover*/
  --color-six: #0099FF; /*Blue*/
  --color-seven: #FFFFFF; /*White*/
  --color-eight: rgba(255, 255, 255, 0.4); /*White Almost*/
  --color-nine: #3BD041; /*Green*/
  --color-ten: #FF2943; /*Red*/

  /*Fonts*/
  --font-primary: Open Sans, serif;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.hyd-logo {
  /* Logo */
  background: var(--color-seven);
}

.hyd-logo:hover {
  cursor: pointer;
}

.hyd-tab, .ant-tabs-nav-container {
  /* Таб */
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
}

.hyd-tab-pane {
  font-weight: 700;
}

.hyd-tab .ant-tabs-tab {
  margin-right: 0;
}

.hyd-input, .hyd-input-select .ant-select-selection, .hyd-input-autocomplete .ant-select-search--inline .ant-select-search__field {
  height: 60px;
  /*background: var(--color-seven);*/
  border-radius: 30px;
  padding-left: 32px;
  color: var(--color-one);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.hyd-input::placeholder, .hyd-input-small::placeholder {
  color: var(--color-one);
}

.hyd-input-small, .hyd-input-select-small .ant-select-selection, .hyd-input-autocomplete-small .ant-select-search--inline .ant-select-search__field {
  height: 45px;
  border-radius: 10px;
  padding: 6px 15px;
  color: var(--color-one);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.hyd-input-small-emphasise {
  height: 45px;
  border-radius: 10px;
  padding: 0 15px;
  color: var(--color-one);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.hyd-input-small-emphasise:disabled {
  background-color: var(--color-seven);
  color: var(--color-one);
  opacity: unset;
}

.hyd-select-small .ant-select {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.ant-input.hyd-input-small, .hyd-input-autocomplete-small .ant-select-search--inline .ant-select-search__field {
  padding-top: 18px;
}

.hyd-select-small .ant-select-selection__rendered {
  margin: 0;
}

.has-error .ant-form-explain {
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  display: none;
}

.show-help-enter {
  display: none;
}

.show-help-leave {
  display: none;
}

.ant-select-auto-complete.ant-select .ant-select-selection--single, .ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  /*border: 1px solid var(--color-two);*/
  border-radius: 30px;
  height: 60px;
}

.hyd-input-autocomplete-small, .ant-select-auto-complete.ant-select .ant-select-selection--single, .ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  border-radius: 10px;
  height: 45px;
}

.hyd-arrow-btn {
  width: 283px;
  height: 72px;
  background: var(--color-three);
  border: 0;
  border-radius: 10px;
  padding: 24px;

  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-one);

  text-align: start;
  white-space: normal;
}

.hyd-arrow-btn-ico {
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  top: 0;
  padding: 24px;
}

.ant-btn.hyd-btn-disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.hyd-arrow-btn, .hyd-arrow-btn:focus {
  background: var(--color-three);
  color: var(--color-one);
}

.hyd-arrow-btn:hover {
  background: var(--color-two);
  color: var(--color-one);
  transition: background .8s;
}

.hyd-tool-btn, .hyd-tool-btn:focus {
  width: 205px;
  height: 94px;
  background: var(--color-three);
  border: 0;
  border-radius: 10px;
  display: grid;
}

.hyd-agent-btn, .hyd-agent-btn:focus {
  width: 205px;
  height: 216px;
  background: var(--color-three);
  border: 0;
  border-radius: 10px;
  display: grid;
  padding: 16px;
  text-align: left;
}

.hyd-agent-btn:hover {
  background: var(--color-three);
  color: var(--color-one);
  transition: background .8s;
}

.hyd-tool-btn:hover {
  background: var(--color-six);

  transition: background .8s;
}

.hyd-tool-btn:hover > div > div {
  color: var(--color-seven);

  transition: color .3s;
}

.hyd-add-tool-btn {
  width: 205px;
  height: 94px;
  background-color: var(--color-seven);
  border: 1px solid var(--color-two);
  border-radius: 10px;
}

.hyd-btn-earned {
  background-color: var(--color-seven);
  border: 1px solid var(--color-two);
  border-radius: 10px;
  height: 40px;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--color-one);
  padding: 10px 15px 10px 15px;
}

.hyd-add-tool-btn-wide {
  width: 100%;
  height: 48px;
}

.hyd-add-tool-btn:hover {
  background: var(--color-six);
  transition: background .8s;
}

.hyd-add-tool-btn:hover rect {
  fill: var(--color-seven);
}

.hyd-btn-small {
  /* Small UI standard Button */
  height: 45px;
  background: var(--color-four);
  border-color: var(--color-five);
  border-radius: 10px;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-one);
  padding: 14px 45px 15px 45px;
}

.hyd-btn-bonus {
  height: 40px;
  background: var(--color-four);
  border-color: var(--color-five);
  border-radius: 10px;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-one);
  padding: 10px 15px 10px 15px;
  margin-left: 10px;
}

.hyd-btn-small.one-click-policy-btn {
  width: 206px;
  height: 42px;
  padding: 13px 24px;
}

.one-click-policy-refresh-btn {
  width: 42px;
  height: 42px;
  padding: 6px;

  border-radius: 6px;
  background: var(--color-one);
  border: 1px solid var(--color-one);
}

.hyd-refresh-ico {
  margin-left: 3px;
}

.icon-hover {
  display: none;
}

.one-click-policy-refresh-btn:hover .icon-default {
  display: none;
}

.one-click-policy-refresh-btn:hover .icon-hover {
  display: inline;
}

.one-click-policy-refresh-btn:hover {
  background: var(--color-four);
}

.hyd-btn-small-2 {
  /* Small UI standard Button */
  height: 40px;
  background: var(--color-four);
  border-color: var(--color-five);
  border-radius: 10px;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-one);
}

.hyd-btn-small-2-white {
  /* Small UI standard Button */
  height: 40px;
  background: var(--color-seven);
  border-color: var(--color-seven);
  border-radius: 10px;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-one);
}

.hyd-btn-small-3 {
  /* Small UI standard Button */
  height: 45px;
  background: var(--color-four);
  border-color: var(--color-five);
  border-radius: 10px;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-one);
  padding: 14px 32px 15px 32px;
}

.hyd-large-btn {
  /* Large Button */
  height: 60px;
  background: var(--color-four);
  border-color: var(--color-four);
  border-radius: 50px;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-one);
  padding-left: 32px;
  padding-right: 32px;
}

.hyd-medium-btn {
  /* Medium Button */
  padding: 13px 24px;
  height: 40px;
  background: var(--color-four);
  border-color: var(--color-four);
  border-radius: 10px;

  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-one);
}

.hyd-medium-btn-grey {
  /* Medium Button Grey */
  padding: 13px 24px;
  height: 40px;
  background: var(--color-three);
  border-color: var(--color-three);
  border-radius: 10px;

  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-one);
}

.hyd-btn-small-grey {
  /* Small UI grey Button */
  height: 45px;
  background: var(--color-three);
  border-color: var(--color-three);
  border-radius: 10px;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-one);
  padding: 12px 24px;
}

.hyd-btn-small-grey-2 {
  /* Smaller UI grey Button */
  height: 40px;
  background: var(--color-three);
  border-color: var(--color-three);
  border-radius: 10px;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-one);
  padding: 13px 24px;
}

.hyd-doc-btn {
  /* Document Button */
  padding: 13px 24px;
  width: 259px;
  height: 42px;

  border: 1px solid var(--color-four);
  border-radius: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;

  color: var(--color-one);
}

.hyd-doc-btn:hover {
  background: var(--color-five);
  border-color: var(--color-five);
  color: var(--color-one);
}

.hyd-text-btn {
  /* Text Button */
  height: 40px;
  border-color: transparent;
  border-radius: 30px;

  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: var(--color-one);
}

.hyd-large-btn:hover, .hyd-medium-btn:hover, .hyd-btn-small:hover,
.hyd-btn-small-2:hover, .hyd-btn-small-3:hover {
  background: var(--color-five);
  border-color: var(--color-five);
  color: var(--color-one);
}

.hyd-large-btn:active, .hyd-medium-btn:active, .hyd-btn-small:active,
.hyd-btn-small-2:active, .hyd-btn-small-3:active {
  background: var(--color-four);
  border-color: var(--color-four);
  color: var(--color-one);
}

.hyd-large-btn:focus, .hyd-medium-btn:focus, .hyd-btn-small:focus,
.hyd-btn-small-2:focus, .hyd-btn-small-3:focus {
  background: var(--color-four);
  border-color: var(--color-four);
  color: var(--color-one);
}

.hyd-medium-btn-grey:hover, .hyd-btn-small-grey:hover, .hyd-btn-small-grey-2:hover {
  background: var(--color-two);
  border-color: var(--color-two);
  color: var(--color-one);
}

.hyd-text-btn:hover {
  border-color: transparent;
  background: transparent;
  color: var(--color-six);
}

.hyd-large-btn:disabled, .hyd-medium-btn:disabled, .hyd-btn-small:disabled,
.hyd-btn-small-2:disabled, .hyd-btn-small-3:disabled {
  background: transparent;
  border-color: var(--color-four);
  color: var(--color-two);
}

.hyd-text-btn:disabled, .hyd-text-btn:active, .hyd-text-btn:before {
  border-color: transparent;
  background: transparent;
  color: var(--color-two);
}

.hyd-link-btn {
  /* Link Button */
  font-size: 16px;
  line-height: 16px;
  background-color: transparent;
  color: var(--color-six);

  border: none;
  box-shadow: 0 0 0 0;
  border-radius: 30px;
}

.hyd-link-btn-2 {
  /* Link Button */
  font-size: 14px;
  line-height: 16px;
  background-color: transparent;
  color: var(--color-six);

  border: none;
  border-radius: 30px;
}

.hyd-link-btn:hover, .hyd-link-btn-2:hover {
  background-color: transparent;
}

.hyd-checkbox {
  color: var(--color-one);

  flex: none;
  order: 1;
  flex-grow: 0;
}

.hyd-content-wrapper {
  background: var(--color-seven);

  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
}

.container {
  max-width: 910px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.hyd-dropdown {
  color: var(--color-one);
  cursor: pointer;
}

.hyd-very-big-bold-text {
  font-weight: bold;
  font-size: 64px;
  line-height: 64px;
  color: var(--color-one);
}

.hyd-very-big-bold-text-small {
  font-weight: bold;
  font-size: 32px;
  line-height: 64px;
  color: var(--color-one);
}

.bold-text-grey {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: var(--color-one);
  opacity: 0.3;
}

.big-bold-text-grey {
  font-weight: bold;
  font-size: 32px;
  line-height: 64px;
  color: var(--color-one);
  opacity: 0.3;
}

.hyd-big-bold-text {
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: var(--color-one);
}

.hyd-bold-text-2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: var(--color-one);
}

.hyd-bold-text {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-one);
}

.hyd-bold-text-small {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-one);
}

.hyd-bold-text-white {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: var(--color-seven);
}

.hyd-norm-text {
  color: var(--color-one);
}

.ant-modal-content, .ant-modal-header {
  border-radius: 10px;
}

.hyd-radio-group .ant-radio-checked .ant-radio-inner::after {
  background-color: var(--color-seven);
  top: 0;
  left: 0;
  width: 6px;
  height: 6px;
}

.hyd-radio-group .ant-radio-checked .ant-radio-inner {
  border: 5px solid var(--color-six);
}

.hyd-radio-group .ant-radio-inner {
  border: 1px solid var(--color-two);
}

.hyd-radio-group {
  font-style: normal;
  font-weight: normal;
}

.ant-table-tbody > tr > td {
  border-bottom: 0 solid #f0f0f0;
}

.ant-table-pagination.ant-pagination {
  float: left;
  margin: 40px 0 7px 0;
}

.ant-pagination {
  font-size: 16px;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-item {
  border: none;
}

.ant-pagination-item {
  line-height: 40px;
  width: 40px;
  height: 40px;
}

.ant-pagination-item > a {
  padding: 0;
  font-weight: bold;
  color: var(--color-one);
}

.ant-pagination-item-active > a {
  background-color: var(--color-six);
  color: var(--color-seven);
  border-radius: 10px;
}

.ant-pagination-item-active:hover > a,
.ant-pagination-item-active:focus > a {
  color: var(--color-seven);
}

.ant-select-selection {
  color: var(--color-one);
  font-weight: normal;
  font-size: 16px;

  border-radius: 10px;
  height: 40px;
}

.ant-select-disabled .ant-select-selection {
  color: var(--color-two);
}

.ant-select-selection-selected-value {
  line-height: 36px;
  padding: 0 8px 0 4px;
}

.hyd-input-select-small .ant-select-selection-selected-value {
  line-height: 20px;
  padding: 0;
}

.hyd-select-value {
  float: left;
}

.hyd-input-select-small .hyd-select-tooltip {
  padding: 0;
  display: flex;
}

.hyd-policy-paid, .hyd-policy-paid:hover, .hyd-policy-paid:focus {
  background: var(--color-nine);
  border-color: var(--color-nine);
  color: var(--color-seven);
  cursor: alias;
}

.layout-wrapper {
  max-width: 910px;
  min-width: 910px;
  /*justify-content: center;*/
  margin-left: auto;
  margin-right: auto;
}

.container-outer {
  padding-left: 16px;
  /*justify-content: center;*/
  /*align-content: center;*/
  /*display: flex;*/
}

#float-label-small label {
  white-space: nowrap;
  margin: 0 15px;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 12px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-one);
}

#float-label-small {
  position: relative;
  display: flex;
  flex-direction: column;
}

#float-label-small .Disabled {
  color: var(--color-two);
}

#float-label-small:focus-within label {
  transform: translate(0, 0) scale(0.8572);
  padding-top: 5px;
  color: var(--color-two);
}

#float-label-small .Active {
  transform: translate(0, 0) scale(0.8572);
  padding-top: 5px;
  color: var(--color-two);
}

.ant-checkbox-inner {
  border-radius: 4px;
}

.hyd-emphasise-block-small {
  display: inline-flex;
  margin-right: 8px;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  background: var(--color-three);
  border-radius: 10px;
}

.step-title-disabled-small {
  display: flex;
  align-items: center;
  color: var(--color-two);
  margin: 44px auto 36px auto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

.hyd-change-button-small.ant-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 16px;

  margin-left: 53px;

  width: 114px;
  height: 40px;

  border: 1px solid var(--color-six);
  box-sizing: border-box;
  border-radius: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;

  color: var(--color-six);
}

.hyd-upload {
  display: inline-grid;
  margin-right: 20px;
  float: left;
}

.upload-btn {
  display: flex;
  height: 45px;
  padding: 16px 24px;
  align-items: center;
  gap: 10px;

  border-radius: 10px;
  border: 1px solid var(--color-two);

  color: var(--color-one);
}

.hyd-grey-txt {
  font-family: var(--font-primary);
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: var(--color-one);
  opacity: 0.3;
}

.cross-dropdown-txt {
  margin-right: 10px;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  color: var(--color-six);
}

.cross-dropdown-icon {
  margin-top: 7px;
}
