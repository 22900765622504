.ant-layout-footer {
  padding: 0 0 0 0;
  background: var(--color-seven);
}

.hyd-footer-container {
  margin: 40px 0;
  position: relative;
  left: 0;
  bottom: 0;
  height: 304px;
  max-width: 910px;
  width: 100%;
  background: var(--color-seven);
}

.div-table {
  display: table;
  width: auto;
  padding: 0 0 0 0;
}

.div-table-row {
  display: table-row;
  width: auto;
  clear: both;
}

.div-table-col {
  float: left; /* fix for  buggy browsers */
  display: table-column;
  width: 180px;
}

.div-table-bold-text {
  font-weight: bold;
  font-size: 16px;

  color: var(--color-one);
}

.div-table-medium-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: var(--color-one);
}

.div-table-text, .div-table-time-text {
  font-size: 12px;
  color: var(--color-one);
}

.div-table-text-disabled {
  color: var(--color-two)
}

.div-table-time-text {
  color: var(--color-two);
}

.hyd-line24 {
  line-height: 24px;
}

.hyd-line16 {
  line-height: 16px;
}

.div-table-text:hover, .rep:hover {
  cursor: pointer;
}

.div-table-text-disabled:hover {
  cursor: not-allowed
}